import React from 'react';
import './VideosFan.css'
function VideosFan(props) {

    return (
        <div className='videos-fan'>
            <div className='vid'>
                <div className='pic-vid'>
                    <div className='vid-top'>
                        <a href={props.url} target="_self" >

                            <svg xmlns="http://www.w3.org/2000/svg" width="148" height="148" viewBox="0 0 148 148" fill="none">
                                <circle cx="74" cy="74" r="74" fill="#FF6600" />
                                <path d="M100.118 71.77C102.35 73.0588 102.35 76.2808 100.118 77.5696L64.4574 98.1583C62.2251 99.4471 59.4347 97.8361 59.4347 95.2585L59.4347 54.0811C59.4347 51.5035 62.2251 49.8925 64.4574 51.1813L100.118 71.77Z" fill="#FEFEFE" />
                            </svg>
                        </a>
                    </div>
                    <div>
                    <img src={props.img} alt='pictures for video' />
                    </div>
                </div>
                <div className='detail-vid'>
                    <div className='header-vid'><p>{props.header}</p></div>
                    <div className='watch-logo-number'>
                        <div className='whatching-time'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="19" viewBox="0 0 28 19" fill="none">
                                <path d="M27.3855 9.19151C27.3458 9.10194 26.3855 6.97149 24.2505 4.8365C21.4057 1.99173 17.8126 0.488281 13.8578 0.488281C9.90306 0.488281 6.30997 1.99173 3.4652 4.8365C1.33021 6.97149 0.365325 9.10534 0.330176 9.19151C0.278602 9.30752 0.251953 9.43305 0.251953 9.56001C0.251953 9.68696 0.278602 9.81249 0.330176 9.9285C0.36986 10.0181 1.33021 12.1474 3.4652 14.2824C6.30997 17.126 9.90306 18.6295 13.8578 18.6295C17.8126 18.6295 21.4057 17.126 24.2505 14.2824C26.3855 12.1474 27.3458 10.0181 27.3855 9.9285C27.4371 9.81249 27.4637 9.68696 27.4637 9.56001C27.4637 9.43305 27.4371 9.30752 27.3855 9.19151ZM13.8578 16.8153C10.3679 16.8153 7.31907 15.5466 4.79518 13.0454C3.75959 12.0155 2.87855 10.8412 2.17944 9.55887C2.87836 8.27645 3.75943 7.10208 4.79518 6.07236C7.31907 3.57115 10.3679 2.3024 13.8578 2.3024C17.3478 2.3024 20.3966 3.57115 22.9205 6.07236C23.9581 7.10183 24.8411 8.2762 25.5419 9.55887C24.7244 11.085 21.1631 16.8153 13.8578 16.8153ZM13.8578 4.11652C12.7814 4.11652 11.7292 4.43571 10.8342 5.03372C9.93924 5.63173 9.24168 6.48171 8.82976 7.47617C8.41784 8.47063 8.31006 9.56491 8.52005 10.6206C8.73005 11.6763 9.24838 12.6461 10.0095 13.4072C10.7706 14.1683 11.7404 14.6867 12.7961 14.8967C13.8518 15.1066 14.9461 14.9989 15.9405 14.587C16.935 14.175 17.785 13.4775 18.383 12.5825C18.981 11.6875 19.3002 10.6353 19.3002 9.55887C19.2987 8.11593 18.7248 6.73252 17.7045 5.7122C16.6842 4.69189 15.3008 4.11802 13.8578 4.11652ZM13.8578 13.1871C13.1402 13.1871 12.4388 12.9743 11.8421 12.5756C11.2454 12.177 10.7804 11.6103 10.5058 10.9473C10.2312 10.2844 10.1593 9.55485 10.2993 8.85104C10.4393 8.14723 10.7849 7.50074 11.2923 6.99332C11.7997 6.4859 12.4462 6.14035 13.15 6.00035C13.8538 5.86035 14.5833 5.93221 15.2463 6.20682C15.9093 6.48143 16.4759 6.94647 16.8746 7.54313C17.2733 8.13979 17.4861 8.84127 17.4861 9.55887C17.4861 10.5211 17.1038 11.444 16.4234 12.1244C15.743 12.8048 14.8201 13.1871 13.8578 13.1871Z" fill="#7D858B" />
                            </svg>

                            <div className='watch-num'>
                                <p>{props.watches}</p>
                            </div>
                        </div>


                        <div><p>{props.time}</p></div>
                    </div>
                </div>


            </div>

        </div>
    )
}

export default VideosFan