
export const data = [{
  'src': require('../../assets/images/NewsForHomePage.png'),
  'text': 'تنطلق منافسات الجولة الخامسة من الدوري السوري، الجمعة، ب4 مباريات، وتستكمل السبت بمواجهة أهلي حلب والكرامة، ثم تختتم بلقاء مؤجل يجمع الفتوة بتشرين، الإثنين المقبل. جبلة × الوثبة يدخل الوثبة المباراة تحت قيادة المدرب مصعب محمد، منتشيا بفوزه على الجيش ثم تشرين، حيث يحتل المركز الرابع ب8 نقاط.في نفس الوضعية يلعب جبلة بحافز كبير بعد الفوز',
  'header': 'الوثبة يصطدم بجبلة.. وأهلي حلب يستضيف الكرامة',
  'time': (new Date()),
}, {
  'src': require('../../assets/images/NewsForHomePage1.png'),
  'text': 'تنطلق منافسات الجولة الخامسة من الدوري السوري، الجمعة، ب4 مباريات، وتستكمل السبت بمواجهة أهلي حلب والكرامة، ثم تختتم بلقاء مؤجل يجمع الفتوة بتشرين، الإثنين المقبل. جبلة × الوثبة يدخل الوثبة المباراة تحت قيادة المدرب مصعب محمد، منتشيا بفوزه على الجيش ثم تشرين، حيث يحتل المركز الرابع ب8 نقاط.في نفس الوضعية يلعب جبلة بحافز كبير بعد الفوز',
  'header': 'الوثبة يصطدم بجبلة.. وأهلي حلب يستضيف الكرامة',
  'time': (new Date()),

}, {
  'src': require('../../assets/images/NewsForHomePage2.png'),
  'text': 'تنطلق منافسات الجولة الخامسة من الدوري السوري، الجمعة، ب4 مباريات، وتستكمل السبت بمواجهة أهلي حلب والكرامة، ثم تختتم بلقاء مؤجل يجمع الفتوة بتشرين، الإثنين المقبل. جبلة × الوثبة يدخل الوثبة المباراة تحت قيادة المدرب مصعب محمد، منتشيا بفوزه على الجيش ثم تشرين، حيث يحتل المركز الرابع ب8 نقاط.في نفس الوضعية يلعب جبلة بحافز كبير بعد الفوز',
  'header': 'الوثبة يصطدم بجبلة.. وأهلي حلب يستضيف الكرامة',
  'time': (new Date()),

}, {
  'src': require('../../assets/images/footerpic.jpg'),
  'text': 'تنطلق منافسات الجولة الخامسة من الدوري السوري، الجمعة، ب4 مباريات، وتستكمل السبت بمواجهة أهلي حلب والكرامة، ثم تختتم بلقاء مؤجل يجمع الفتوة بتشرين، الإثنين المقبل. جبلة × الوثبة يدخل الوثبة المباراة تحت قيادة المدرب مصعب محمد، منتشيا بفوزه على الجيش ثم تشرين، حيث يحتل المركز الرابع ب8 نقاط.في نفس الوضعية يلعب جبلة بحافز كبير بعد الفوز',
  'header': 'الوثبة يصطدم بجبلة.. وأهلي حلب يستضيف الكرامة',
  'time': (new Date()),

}, {
  'src': require('../../assets/images/news1.png'),
  'text': 'تنطلق منافسات الجولة الخامسة من الدوري السوري، الجمعة، ب4 مباريات، وتستكمل السبت بمواجهة أهلي حلب والكرامة، ثم تختتم بلقاء مؤجل يجمع الفتوة بتشرين، الإثنين المقبل. جبلة × الوثبة يدخل الوثبة المباراة تحت قيادة المدرب مصعب محمد، منتشيا بفوزه على الجيش ثم تشرين، حيث يحتل المركز الرابع ب8 نقاط.في نفس الوضعية يلعب جبلة بحافز كبير بعد الفوز',
  'header': 'الوثبة يصطدم بجبلة.. وأهلي حلب يستضيف الكرامة',
  'time': (new Date()),

}, {
  'src': require('../../assets/images/news-image.jpg'),
  'text': 'تنطلق منافسات الجولة الخامسة من الدوري السوري، الجمعة، ب4 مباريات، وتستكمل السبت بمواجهة أهلي حلب والكرامة، ثم تختتم بلقاء مؤجل يجمع الفتوة بتشرين، الإثنين المقبل. جبلة × الوثبة يدخل الوثبة المباراة تحت قيادة المدرب مصعب محمد، منتشيا بفوزه على الجيش ثم تشرين، حيث يحتل المركز الرابع ب8 نقاط.في نفس الوضعية يلعب جبلة بحافز كبير بعد الفوز',
  'header': 'الوثبة يصطدم بجبلة.. وأهلي حلب يستضيف الكرامة',
  'time': (new Date()),

}, {
  'src': require('../../assets/images/news-image.jpg'),
  'text': 'تنطلق منافسات الجولة الخامسة من الدوري السوري، الجمعة، ب4 مباريات، وتستكمل السبت بمواجهة أهلي حلب والكرامة، ثم تختتم بلقاء مؤجل يجمع الفتوة بتشرين، الإثنين المقبل. جبلة × الوثبة يدخل الوثبة المباراة تحت قيادة المدرب مصعب محمد، منتشيا بفوزه على الجيش ثم تشرين، حيث يحتل المركز الرابع ب8 نقاط.في نفس الوضعية يلعب جبلة بحافز كبير بعد الفوز',
  'header': 'الوثبة يصطدم بجبلة.. وأهلي حلب يستضيف الكرامة',
  'time': (new Date()),

}, {
  'src': require('../../assets/images/news3.png'),
  'text': 'تنطلق منافسات الجولة الخامسة من الدوري السوري، الجمعة، ب4 مباريات، وتستكمل السبت بمواجهة أهلي حلب والكرامة، ثم تختتم بلقاء مؤجل يجمع الفتوة بتشرين، الإثنين المقبل. جبلة × الوثبة يدخل الوثبة المباراة تحت قيادة المدرب مصعب محمد، منتشيا بفوزه على الجيش ثم تشرين، حيث يحتل المركز الرابع ب8 نقاط.في نفس الوضعية يلعب جبلة بحافز كبير بعد الفوز',
  'header': 'الوثبة يصطدم بجبلة.. وأهلي حلب يستضيف الكرامة',
  'time': (new Date()),

}, {
  'src': require('../../assets/images/news1.png'),
  'text': 'تنطلق منافسات الجولة الخامسة من الدوري السوري، الجمعة، ب4 مباريات، وتستكمل السبت بمواجهة أهلي حلب والكرامة، ثم تختتم بلقاء مؤجل يجمع الفتوة بتشرين، الإثنين المقبل. جبلة × الوثبة يدخل الوثبة المباراة تحت قيادة المدرب مصعب محمد، منتشيا بفوزه على الجيش ثم تشرين، حيث يحتل المركز الرابع ب8 نقاط.في نفس الوضعية يلعب جبلة بحافز كبير بعد الفوز',
  'header': 'الوثبة يصطدم بجبلة.. وأهلي حلب يستضيف الكرامة',
  'time': (new Date()),

}, {
  'src': require('../../assets/images/news4.png'),
  'text': 'تنطلق منافسات الجولة الخامسة من الدوري السوري، الجمعة، ب4 مباريات، وتستكمل السبت بمواجهة أهلي حلب والكرامة، ثم تختتم بلقاء مؤجل يجمع الفتوة بتشرين، الإثنين المقبل. جبلة × الوثبة يدخل الوثبة المباراة تحت قيادة المدرب مصعب محمد، منتشيا بفوزه على الجيش ثم تشرين، حيث يحتل المركز الرابع ب8 نقاط.في نفس الوضعية يلعب جبلة بحافز كبير بعد الفوز',
  'header': 'الوثبة يصطدم بجبلة.. وأهلي حلب يستضيف الكرامة',
  'time': (new Date()),

}, {
  'src': require('../../assets/images/News5.png'),
  'text': 'تنطلق منافسات الجولة الخامسة من الدوري السوري، الجمعة، ب4 مباريات، وتستكمل السبت بمواجهة أهلي حلب والكرامة، ثم تختتم بلقاء مؤجل يجمع الفتوة بتشرين، الإثنين المقبل. جبلة × الوثبة يدخل الوثبة المباراة تحت قيادة المدرب مصعب محمد، منتشيا بفوزه على الجيش ثم تشرين، حيث يحتل المركز الرابع ب8 نقاط.في نفس الوضعية يلعب جبلة بحافز كبير بعد الفوز',
  'header': 'الوثبة يصطدم بجبلة.. وأهلي حلب يستضيف الكرامة',
  'time': (new Date()),

}, {
  'src': require('../../assets/images/news1.png'),
  'text': 'تنطلق منافسات الجولة الخامسة من الدوري السوري، الجمعة، ب4 مباريات، وتستكمل السبت بمواجهة أهلي حلب والكرامة، ثم تختتم بلقاء مؤجل يجمع الفتوة بتشرين، الإثنين المقبل. جبلة × الوثبة يدخل الوثبة المباراة تحت قيادة المدرب مصعب محمد، منتشيا بفوزه على الجيش ثم تشرين، حيث يحتل المركز الرابع ب8 نقاط.في نفس الوضعية يلعب جبلة بحافز كبير بعد الفوز',
  'header': 'الوثبة يصطدم بجبلة.. وأهلي حلب يستضيف الكرامة',
  'time': (new Date()),

}, {
  'src': require('../../assets/images/news1.png'),
  'text': 'تنطلق منافسات الجولة الخامسة من الدوري السوري، الجمعة، ب4 مباريات، وتستكمل السبت بمواجهة أهلي حلب والكرامة، ثم تختتم بلقاء مؤجل يجمع الفتوة بتشرين، الإثنين المقبل. جبلة × الوثبة يدخل الوثبة المباراة تحت قيادة المدرب مصعب محمد، منتشيا بفوزه على الجيش ثم تشرين، حيث يحتل المركز الرابع ب8 نقاط.في نفس الوضعية يلعب جبلة بحافز كبير بعد الفوز',
  'header': 'الوثبة يصطدم بجبلة.. وأهلي حلب يستضيف الكرامة',
  'time': (new Date()),

}, {
  'src': require('../../assets/images/news1.png'),
  'text': 'تنطلق منافسات الجولة الخامسة من الدوري السوري، الجمعة، ب4 مباريات، وتستكمل السبت بمواجهة أهلي حلب والكرامة، ثم تختتم بلقاء مؤجل يجمع الفتوة بتشرين، الإثنين المقبل. جبلة × الوثبة يدخل الوثبة المباراة تحت قيادة المدرب مصعب محمد، منتشيا بفوزه على الجيش ثم تشرين، حيث يحتل المركز الرابع ب8 نقاط.في نفس الوضعية يلعب جبلة بحافز كبير بعد الفوز',
  'header': 'الوثبة يصطدم بجبلة.. وأهلي حلب يستضيف الكرامة',
  'time': (new Date()),

}, {
  'src': require('../../assets/images/news1.png'),
  'text': 'تنطلق منافسات الجولة الخامسة من الدوري السوري، الجمعة، ب4 مباريات، وتستكمل السبت بمواجهة أهلي حلب والكرامة، ثم تختتم بلقاء مؤجل يجمع الفتوة بتشرين، الإثنين المقبل. جبلة × الوثبة يدخل الوثبة المباراة تحت قيادة المدرب مصعب محمد، منتشيا بفوزه على الجيش ثم تشرين، حيث يحتل المركز الرابع ب8 نقاط.في نفس الوضعية يلعب جبلة بحافز كبير بعد الفوز',
  'header': 'الوثبة يصطدم بجبلة.. وأهلي حلب يستضيف الكرامة',
  'time': (new Date()),

}, {
  'src': require('../../assets/images/news1.png'),
  'text': 'تنطلق منافسات الجولة الخامسة من الدوري السوري، الجمعة، ب4 مباريات، وتستكمل السبت بمواجهة أهلي حلب والكرامة، ثم تختتم بلقاء مؤجل يجمع الفتوة بتشرين، الإثنين المقبل. جبلة × الوثبة يدخل الوثبة المباراة تحت قيادة المدرب مصعب محمد، منتشيا بفوزه على الجيش ثم تشرين، حيث يحتل المركز الرابع ب8 نقاط.في نفس الوضعية يلعب جبلة بحافز كبير بعد الفوز',
  'header': 'الوثبة يصطدم بجبلة.. وأهلي حلب يستضيف الكرامة',
  'time': (new Date()),

}, {
  'src': require('../../assets/images/news1.png'),
  'text': 'تنطلق منافسات الجولة الخامسة من الدوري السوري، الجمعة، ب4 مباريات، وتستكمل السبت بمواجهة أهلي حلب والكرامة، ثم تختتم بلقاء مؤجل يجمع الفتوة بتشرين، الإثنين المقبل. جبلة × الوثبة يدخل الوثبة المباراة تحت قيادة المدرب مصعب محمد، منتشيا بفوزه على الجيش ثم تشرين، حيث يحتل المركز الرابع ب8 نقاط.في نفس الوضعية يلعب جبلة بحافز كبير بعد الفوز',
  'header': 'الوثبة يصطدم بجبلة.. وأهلي حلب يستضيف الكرامة',
  'time': (new Date()),

}, {
  'src': require('../../assets/images/news1.png'),
  'text': 'تنطلق منافسات الجولة الخامسة من الدوري السوري، الجمعة، ب4 مباريات، وتستكمل السبت بمواجهة أهلي حلب والكرامة، ثم تختتم بلقاء مؤجل يجمع الفتوة بتشرين، الإثنين المقبل. جبلة × الوثبة يدخل الوثبة المباراة تحت قيادة المدرب مصعب محمد، منتشيا بفوزه على الجيش ثم تشرين، حيث يحتل المركز الرابع ب8 نقاط.في نفس الوضعية يلعب جبلة بحافز كبير بعد الفوز',
  'header': 'الوثبة يصطدم بجبلة.. وأهلي حلب يستضيف الكرامة',
  'time': (new Date()),

}, {
  'src': require('../../assets/images/news1.png'),
  'text': 'تنطلق منافسات الجولة الخامسة من الدوري السوري، الجمعة، ب4 مباريات، وتستكمل السبت بمواجهة أهلي حلب والكرامة، ثم تختتم بلقاء مؤجل يجمع الفتوة بتشرين، الإثنين المقبل. جبلة × الوثبة يدخل الوثبة المباراة تحت قيادة المدرب مصعب محمد، منتشيا بفوزه على الجيش ثم تشرين، حيث يحتل المركز الرابع ب8 نقاط.في نفس الوضعية يلعب جبلة بحافز كبير بعد الفوز',
  'header': 'الوثبة يصطدم بجبلة.. وأهلي حلب يستضيف الكرامة',
  'time': (new Date()),

}, {
  'src': require('../../assets/images/news1.png'),
  'text': 'تنطلق منافسات الجولة الخامسة من الدوري السوري، الجمعة، ب4 مباريات، وتستكمل السبت بمواجهة أهلي حلب والكرامة، ثم تختتم بلقاء مؤجل يجمع الفتوة بتشرين، الإثنين المقبل. جبلة × الوثبة يدخل الوثبة المباراة تحت قيادة المدرب مصعب محمد، منتشيا بفوزه على الجيش ثم تشرين، حيث يحتل المركز الرابع ب8 نقاط.في نفس الوضعية يلعب جبلة بحافز كبير بعد الفوز',
  'header': 'الوثبة يصطدم بجبلة.. وأهلي حلب يستضيف الكرامة',
  'time': (new Date()),

}, {
  'src': require('../../assets/images/news1.png'),
  'text': 'تنطلق منافسات الجولة الخامسة من الدوري السوري، الجمعة، ب4 مباريات، وتستكمل السبت بمواجهة أهلي حلب والكرامة، ثم تختتم بلقاء مؤجل يجمع الفتوة بتشرين، الإثنين المقبل. جبلة × الوثبة يدخل الوثبة المباراة تحت قيادة المدرب مصعب محمد، منتشيا بفوزه على الجيش ثم تشرين، حيث يحتل المركز الرابع ب8 نقاط.في نفس الوضعية يلعب جبلة بحافز كبير بعد الفوز',
  'header': 'الوثبة يصطدم بجبلة.. وأهلي حلب يستضيف الكرامة',
  'time': (new Date()),

}, {
  'src': require('../../assets/images/news1.png'),
  'text': 'تنطلق منافسات الجولة الخامسة من الدوري السوري، الجمعة، ب4 مباريات، وتستكمل السبت بمواجهة أهلي حلب والكرامة، ثم تختتم بلقاء مؤجل يجمع الفتوة بتشرين، الإثنين المقبل. جبلة × الوثبة يدخل الوثبة المباراة تحت قيادة المدرب مصعب محمد، منتشيا بفوزه على الجيش ثم تشرين، حيث يحتل المركز الرابع ب8 نقاط.في نفس الوضعية يلعب جبلة بحافز كبير بعد الفوز',
  'header': 'الوثبة يصطدم بجبلة.. وأهلي حلب يستضيف الكرامة',
  'time': (new Date()),

}, {
  'src': require('../../assets/images/news1.png'),
  'text': 'تنطلق منافسات الجولة الخامسة من الدوري السوري، الجمعة، ب4 مباريات، وتستكمل السبت بمواجهة أهلي حلب والكرامة، ثم تختتم بلقاء مؤجل يجمع الفتوة بتشرين، الإثنين المقبل. جبلة × الوثبة يدخل الوثبة المباراة تحت قيادة المدرب مصعب محمد، منتشيا بفوزه على الجيش ثم تشرين، حيث يحتل المركز الرابع ب8 نقاط.في نفس الوضعية يلعب جبلة بحافز كبير بعد الفوز',
  'header': 'الوثبة يصطدم بجبلة.. وأهلي حلب يستضيف الكرامة',
  'time': (new Date()),

}, {
  'src': require('../../assets/images/news1.png'),
  'text': 'تنطلق منافسات الجولة الخامسة من الدوري السوري، الجمعة، ب4 مباريات، وتستكمل السبت بمواجهة أهلي حلب والكرامة، ثم تختتم بلقاء مؤجل يجمع الفتوة بتشرين، الإثنين المقبل. جبلة × الوثبة يدخل الوثبة المباراة تحت قيادة المدرب مصعب محمد، منتشيا بفوزه على الجيش ثم تشرين، حيث يحتل المركز الرابع ب8 نقاط.في نفس الوضعية يلعب جبلة بحافز كبير بعد الفوز',
  'header': 'الوثبة يصطدم بجبلة.. وأهلي حلب يستضيف الكرامة',
  'time': (new Date()),

}, {
  'src': require('../../assets/images/news1.png'),
  'text': 'تنطلق منافسات الجولة الخامسة من الدوري السوري، الجمعة، ب4 مباريات، وتستكمل السبت بمواجهة أهلي حلب والكرامة، ثم تختتم بلقاء مؤجل يجمع الفتوة بتشرين، الإثنين المقبل. جبلة × الوثبة يدخل الوثبة المباراة تحت قيادة المدرب مصعب محمد، منتشيا بفوزه على الجيش ثم تشرين، حيث يحتل المركز الرابع ب8 نقاط.في نفس الوضعية يلعب جبلة بحافز كبير بعد الفوز',
  'header': 'الوثبة يصطدم بجبلة.. وأهلي حلب يستضيف الكرامة',
  'time': (new Date()),

}, {
  'src': require('../../assets/images/news1.png'),
  'text': 'تنطلق منافسات الجولة الخامسة من الدوري السوري، الجمعة، ب4 مباريات، وتستكمل السبت بمواجهة أهلي حلب والكرامة، ثم تختتم بلقاء مؤجل يجمع الفتوة بتشرين، الإثنين المقبل. جبلة × الوثبة يدخل الوثبة المباراة تحت قيادة المدرب مصعب محمد، منتشيا بفوزه على الجيش ثم تشرين، حيث يحتل المركز الرابع ب8 نقاط.في نفس الوضعية يلعب جبلة بحافز كبير بعد الفوز',
  'header': 'الوثبة يصطدم بجبلة.. وأهلي حلب يستضيف الكرامة',
  'time': (new Date()),

},]


export const data2 = [{
  team1: require('../../assets/images/team1.jpg')
  , team2: require('../../assets/images/team2.jpg'),
  team1Name: 'Alkarama',
  team2Name: 'albath',
  time: (new Date()),
}, {
  team1: require('../../assets/images/team1.jpg')
  , team2: require('../../assets/images/team2.jpg'),
  team1Name: 'Alkarama',
  team2Name: 'albath',
  time: (new Date()),
}, {
  team1: require('../../assets/images/team1.jpg')
  , team2: require('../../assets/images/team2.jpg'),
  team1Name: 'Alkarama',
  team2Name: 'albath',
  time: (new Date()),
}, {
  team1: require('../../assets/images/team1.jpg')
  , team2: require('../../assets/images/team2.jpg'),
  team1Name: 'Alkarama',
  team2Name: 'albath',
  time: (new Date()),
}]


export const data3 = [
  {
    team: 'alkarama',
    play: 12,
    loss: 5,
    drowup: 2,
    win: 7,
    remainder: 0,
    '+/-': 3,
    deference: 3,
    point: 6,
    src:require('../../assets/images/logo_alkarama.png')
  },





  {
    team: 'alkarama',
    play: 12,
    loss: 5,
    drowup: 2,
    win: 7,
    remainder: 0,
    '+/-': 3,
    deference: 3,
    point: 6,
    src:require('../../assets/images/logo_alkarama.png')
  },
  {
    team: 'alkarama',
    play: 12,
    loss: 5,
    drowup: 2,
    win: 7,
    remainder: 0,
    '+/-': 3,
    deference: 3,
    point: 6,
    src:require('../../assets/images/logo_alkarama.png')
  },


  {
    team: 'alkarama',
    play: 12,
    loss: 5,
    drowup: 2,
    win: 7,
    remainder: 0,
    '+/-': 3,
    deference: 3,
    point: 6,
    src:require('../../assets/images/logo_alkarama.png')
  },
  {
    team: 'alkarama',
    play: 12,
    loss: 5,
    drowup: 2,
    win: 7,
    remainder: 0,
    '+/-': 3,
    deference: 3,
    point: 6,
    src:require('../../assets/images/logo_alkarama.png')
  },
]

export const data4=[{
  date:`${(new Date()).getFullYear()}/${(new Date()).getMonth()}/${(new Date()).getDay()}`,
  time:`${(new Date().getHours())}: ${(new Date().getMinutes())}`,
  stage:'ملعب خالد بن الوليد',
  theResult:'0:1',
  team1:{name:'الكرامة',img:require('../images/logo_alkarama.png'),result:'0'},
  team2:{name:'جبلة',img:require('../images/japleh_team.png'),result:'1'},
},{
  date:`${(new Date()).getFullYear()}/${(new Date()).getMonth()}/${(new Date()).getDay()}`,
  time:`${(new Date().getHours())}: ${(new Date().getMinutes())}`,
  stage:'ملعب خالد بن الوليد',
  theResult:'0:1',
  team1:{name:'الكرامة',img:require('../images/logo_alkarama.png'),result:'0'},
  team2:{name:'جبلة',img:require('../images/japleh_team.png'),result:'1'},
},{
  date:`${(new Date()).getFullYear()}/${(new Date()).getMonth()}/${(new Date()).getDay()}`,
  time:`${(new Date().getHours())}: ${(new Date().getMinutes())}`,
  stage:'ملعب خالد بن الوليد',
  theResult:'0:1',
  team1:{name:'الكرامة',img:require('../images/logo_alkarama.png'),result:'0'},
  team2:{name:'جبلة',img:require('../images/japleh_team.png'),result:'1'},
},{
  date:`${(new Date()).getFullYear()}/${(new Date()).getMonth()}/${(new Date()).getDay()}`,
  time:`${(new Date().getHours())}: ${(new Date().getMinutes())}`,
  stage:'ملعب خالد بن الوليد',
  theResult:'0:1',
  team1:{name:'الكرامة',img:require('../images/logo_alkarama.png'),result:'0'},
  team2:{name:'جبلة',img:require('../images/japleh_team.png'),result:'1'},
},{
  date:`${(new Date()).getFullYear()}/${(new Date()).getMonth()}/${(new Date()).getDay()}`,
  time:`${(new Date().getHours())}: ${(new Date().getMinutes())}`,
  stage:'ملعب خالد بن الوليد',
  theResult:'0:1',
  team1:{name:'الكرامة',img:require('../images/logo_alkarama.png'),result:'0'},
  team2:{name:'جبلة',img:require('../images/japleh_team.png'),result:'1'},
},{
  date:`${(new Date()).getFullYear()}/${(new Date()).getMonth()}/${(new Date()).getDay()}`,
  time:`${(new Date().getHours())}: ${(new Date().getMinutes())}`,
  stage:'ملعب خالد بن الوليد',
  theResult:'0:1',
  team1:{name:'الكرامة',img:require('../images/logo_alkarama.png'),result:'0'},
  team2:{name:'جبلة',img:require('../images/japleh_team.png'),result:'1'},
},{
  date:`${(new Date()).getFullYear()}/${(new Date()).getMonth()}/${(new Date()).getDay()}`,
  time:`${(new Date().getHours())}: ${(new Date().getMinutes())}`,
  stage:'ملعب خالد بن الوليد',
  theResult:'0:1',
  team1:{name:'الكرامة',img:require('../images/logo_alkarama.png'),result:'0'},
  team2:{name:'جبلة',img:require('../images/japleh_team.png'),result:'1'},
},{
  date:`${(new Date()).getFullYear()}/${(new Date()).getMonth()}/${(new Date()).getDay()}`,
  time:`${(new Date().getHours())}: ${(new Date().getMinutes())}`,
  stage:'ملعب خالد بن الوليد',
  theResult:'0:1',
  team1:{name:'الكرامة',img:require('../images/logo_alkarama.png'),result:'0'},
  team2:{name:'جبلة',img:require('../images/japleh_team.png'),result:'1'},
},{
  date:`${(new Date()).getFullYear()}/${(new Date()).getMonth()}/${(new Date()).getDay()}`,
  time:`${(new Date().getHours())}: ${(new Date().getMinutes())}`,
  stage:'ملعب خالد بن الوليد',
  theResult:'0:1',
  team1:{name:'الكرامة',img:require('../images/logo_alkarama.png'),result:'0'},
  team2:{name:'جبلة',img:require('../images/japleh_team.png'),result:'1'},
},]
