import { Advertisements, Bosses, PlayersTeam, VideosForFan1 } from '../../../Sections/DashBoard';
import './About.css';

const About = () => {
return <>
<Advertisements/>
<PlayersTeam/>
<Bosses/>
<VideosForFan1/>
</>   
};

export default About;